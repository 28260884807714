import React from "react";

const SuccessSurvayAddedMobile = ({
  setIsOpenFeedBack,
  survayServices,
  feedBackHandler,
  setIsRequestSurvay,
  FeedBackListHandler,
  lang,
  arPhrase,
  enPhrase,
  expandHandler,
  setFeedBackIsOpen,
  specificSurvey,
  setSpecificSurvey,
  feedBackIsOpen,
  sidebarHeight,
  isOrderDetails
}) => {

    const handleButtonClick = () => {
        // Set a flag in localStorage to indicate a refresh
        localStorage.setItem("refreshAndRunFunction", "true");
        // Refresh the page
        window.location.reload();
      };
      
  return (
    <div className="relative p-[32px] flex flex-col items-center">
      <img
        src="/assets/img/case-overview/closedIconBackGround.svg"
        alt="close-icon"
        className={`cursor-pointer absolute top-[16px] ${
          lang === "en" ? "right-[16px]" : "left-[16px]"
        }`}
        // onClick={feedBackHandler}
        onClick={() => {
          feedBackHandler();
          window.location.reload();
        }}
      />
      <img
        src="/assets/img/case-overview/success-icon.svg"
        alt=""
        className="w-[88px] h-[88px] mb-[24px]"
      />
      <p className="font-bold font-Almarai text-[20px] leading-[32px] text-center mb-[16px]">
        {lang === "en"
          ? enPhrase["lblSuccessMessageTxt"]
          : arPhrase["lblSuccessMessageTxt"]}
      </p>
      <p className="font-normal font-Almarai text-[16px] leading-[24px] text-center mb-[24px] ">
        {lang === "en"
          ? enPhrase["lblSuccessMessageTxt01"]
          : arPhrase["lblSuccessMessageTxt01"]}
      </p>

      {/* <button
          className="w-full h-[48px] rounded-[4px] py-[24px] px-[8px] flex items-center justify-center bg-[#F2ECCF]"
          onClick={() => {
            feedBackHandler();
            window.location.reload();
          }}
        >
          <span className="font-bold font-Almarai text-[16px] leading-[18px] text-center text-[#A98031] ">
            {lang === "en" ? enPhrase["btnFinish"] : arPhrase["btnFinish"]}
          </span>
        </button> */}

      {survayServices?.services?.length !== 1 && !isOrderDetails  ? (
        <button
          className="w-[200px] h-[48px] rounded-[4px] py-[24px] px-[8px] flex items-center justify-center bg-[#A98031]"
          // onClick={handleClick}
          onClick={handleButtonClick}
        >
          <span className="font-bold font-Almarai text-[16px] leading-[18px] text-center text-[#fff] ">
            {lang === "en"
              ? enPhrase["lblCompletesurveys"]
              : arPhrase["lblCompletesurveys"]}
          </span>
        </button>
      ) : (
        <button
          className="w-[82px] h-[48px] rounded-[4px] py-[24px] px-[8px] flex items-center justify-center bg-[#F2ECCF]"
          onClick={() => {
            feedBackHandler();
            window.location.reload();
          }}
        >
          <span className="font-bold font-Almarai text-[16px] leading-[18px] text-center text-[#A98031] ">
            {lang === "en" ? enPhrase["btnFinish"] : arPhrase["btnFinish"]}
          </span>
        </button>
      )}
    </div>
  );
};

export default SuccessSurvayAddedMobile;
