
import { Link } from "react-router-dom";

const SuccessPayment = ({ setOpenModal, lang, enPhrase, arPhrase, serviceid, isPaid, ppidValue }) => {
  // const changePaymentStatus = () => {
  //   setOpenModal(false)
  // }


  return (
    <div className="flex flex-col p-[32px] items-center">
      <img
        src="/assets/img/payment/success.svg"
        alt="success"
        className="w-[72px] h-[72px] mb-[32px]"
      />
      <p className="text-[18px] md:text-[24px]  text-[#2E7E31] font-bold text-center max-w-[296px] mb-[12px]">
        {lang === "en"
          ? enPhrase["lblPaymentCompleted"]
          : arPhrase["lblPaymentCompleted"]}
      </p>
      <p className="mb-[16px]  font-Almarai text-base text-[#666] text-center max-w-[296px]">
        {lang === "en"
          ? enPhrase["lblThanksForPaymenyt"]
          : arPhrase["lblThanksForPaymenyt"]}
      </p>
      <p className="w-full text-[16px] leading-[1.5rem]  text-[#010202] text-center font-Almarai font-bold max-w-[296px]">
        {lang === "en"
          ? enPhrase["lblPayDifferece"]
          : arPhrase["lblPayDifferece"]}
      </p>
      <Link
        to="/case-overview"
        className="flex flex-row justify-center items-center gap-2 w-full h-[48px] text-sm font-bold bg-[#B68A35] px-3 rounded-3 text-white mt-[32px]"
        onClick={() => setOpenModal(false)}
      >
        <span className="text-[14px]"> {lang === "en"
          ? enPhrase["lblOk"]
          : arPhrase["lblOk"]}</span>
      </Link>
    </div>
  );
};

export default SuccessPayment;
