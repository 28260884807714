import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";

function useOutsideAlerter(
  ref,
  setModal,
  isPhisical,
  isAdmission,
  requestSuccessMessage,
  isFaildUpload,
  isSuccessUpload,
  isSuccessPayment,
  isFailedPayment,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !isPhisical &&
        !isAdmission &&
        !requestSuccessMessage &&
        !isSuccessUpload &&
        isSuccessPayment &&
        isFailedPayment
      ) {
        setModal(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function SharedModal({
  setOpenModal,
  children,
  contactUsShowModel,
  modalWidth = "480px",
  modalMinWidth = "auto",
  isContactUS,
  requestSuccessMessage,
  showImage,
  isHappiness,
  isPhisical,
  isReceiptForm,
  isSuccessUpload,
  isFaildUpload,
  isNeedData,
  isAdmission,
  isPaymentConfirm,
  isFailedPayment,
  isSuccessPayment,
  isSuccessSurvayMobile
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(
    wrapperRef,
    setOpenModal,
    isPhisical,
    isAdmission,
    requestSuccessMessage,
    isFaildUpload,
    isSuccessUpload
  );

  return (
    <div className={styles.modalBg}>
      <div
        ref={wrapperRef}
        className={
          isContactUS
            ? styles.modalContainerContact
            : requestSuccessMessage
            ? styles.modalRequestSuccessMessage
            : showImage
            ? styles.modalShowImage
            : isHappiness
            ? styles.modalHappinessContainer
            : isPhisical
            ? styles.modalPhisical
            : isReceiptForm
            ? styles.modalReceiptForm
            : isSuccessUpload
            ? styles.modalSuccessUpload
            : isNeedData
            ? styles.modalNeedMoreData
            : isAdmission
            ? styles.modalAdmission
            : isFailedPayment
            ? styles.modalFailedPayment
            : isSuccessPayment
            ? styles.modalSuccessPayment
            : isSuccessSurvayMobile
            ? styles.successMobileSurvey
            :styles.modalContainer
        }
        style={{
          width: modalWidth,
          minWidth: modalMinWidth,
        }}
      >
        {children}
      </div>
    </div>
  );
}

SharedModal.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SharedModal;
