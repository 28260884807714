import React, { useEffect, useState } from "react";

const BackToTop = () => {
  const [backToTop, setBackToTop] = useState(false);
  const lang = window.localStorage.getItem("lang");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTop(true);
      } else {
        setBackToTop(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {backToTop && (
        <button
          className={`fixed z-[20] bottom-[130px] ${lang ==="en"? "right-[39px] xl:right-[59px]":"left-[39px] xl:left-[59px]"}  w-[48px] h-[48px]  border-[1px] border-solid border-[#fff] bg-[#361E12] rounded-[50%] flex items-center justify-center`}
          onClick={scrollUp}
        >
          {/* <span className="text-[#fff] w-[32px] h-[32px] flex items-center justify-center m-auto text-[20px]" >^</span> */}
          <img
            src="/assets/img/home/up-to-top.svg"
            alt=""
            className="w-[24px] h-[24px] xl:w-[32px] xl:h-[32px]"
          />
        </button>
      )}
    </div>
  );
};

export default BackToTop;
