import { LiveChatWidget } from "@livechat/widget-react";
import classNames from "classnames/bind";
import { PhrasesContext } from "../../context/phrasesContext";

import { React, useContext, useEffect, useRef, useState } from "react";


const cx = classNames;

const ContactPop = (props) => {
  const { Lang, setopenContactPop } = props;
  const [chatBtn, setChatBtn] = useState(true);

  const lang = window.localStorage.getItem("lang");
  // const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  const wrapperRef = useRef(null);

  const ChatHandler = () => {
    if (chatBtn) {
      setChatBtn(false);
    } else {
      setChatBtn(true);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setopenContactPop(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const chatSettings = {
    language: 'ar', // Set language to Arabic
  };

  return (
    <>
      <div
        className={cx(
          "absolute  z-[2] bg-[#b68a35] h-[4px] w-[100px]",
          Lang === "en" ? "left-[0px] bottom-[4px]" : "right-[0px] bottom-[0px]"
        )}
      ></div>
      <div
        ref={wrapperRef}
        className={cx(
          "absolute z-[2] bg-[#fff] w-[256px] h-[329px] p-[16px] rounded-[8px] top-[64px] flex flex-col gap-[16px]",
          Lang === "en" ? "left-[-72px]" : "right-[-72px]"
        )}
      >
        <div className="flex flex-col gap-[16px] border-b-[1px] border-[#f2eccf] pb-[16px]">
          <h2 className="text-[16px] text-[#020202]">
            {lang === "en"
              ? enPhrase["lblContactUs"]
              : arPhrase["lblContactUs"]}
          </h2>
          <div className="flex gap-[12px] bg-[#f9f7ed] rounded-[8px] pt-[8px] pb-[8px] h-[50px] ps-[12px]">
            <div className="flex">
              <img src="/assets/img/home/phone.svg" alt="phone" />
            </div>
            <div className="flex flex-col items-start	 gap-[4px] border-s-[1px] border-[#d7bc6d] ps-[16px]">
              <p className="text-[11px] text-[#888] font-bold leading-none">
                {lang === "en"
                  ? enPhrase["lblOurHotline"]
                  : arPhrase["lblOurHotline"]}
              </p>
              <a
                className="text-[16px] text-[#020202] font-bold"
                href={`tel:${"600 55 33 55"}`}
                dir="ltr"
              >
                {"600 55 33 55"}
              </a>
            </div>
          </div>
          <div className="flex gap-[12px] bg-[#f9f7ed] rounded-[8px] pt-[8px] pb-[8px] h-[50px] ps-[12px]">
            <div className="flex">
              <img src="/assets/img/home/email.svg" alt="email" />
            </div>
            <div className="flex flex-col items-start	 gap-[4px] border-s-[1px] border-[#d7bc6d] ps-[16px]">
              <p className="text-[11px] text-[#888] font-bold leading-none">
                {Lang === "en"
                  ? props.enPhrase["lblEmail"]
                  : props.arPhrase["lblEmail"]}
              </p>
              <a
                className="text-[16px] text-[#020202] font-bold"
                href={`mailto:${"info@eje.ae"}`}
              >
                {"info@eje.ae"}
              </a>
            </div>
          </div>
          <div className="flex gap-[8px] justify-center">
            <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]">
              <a
                href={`https://www.facebook.com/ejeuae`}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/img/home/facebook.svg" alt="facebook" />
              </a>
            </div>
            <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]">
              <a
                href={`https://twitter.com/ejeuae`}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/img/home/twitter.svg" alt="twitter" />
              </a>
            </div>
            {/* <div className='flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]'>
              <a
                href={`https://www.linkedin.com/company/ejeuae/`}
                target='_blank'
                rel='noreferrer'>
                <img src='/assets/img/home/linkedin.svg' alt='linkedin' />
              </a>
            </div> */}
            <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]">
              <a
                href={`https://www.instagram.com/ejeuae/`}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/img/home/instagram.svg" alt="instagram" />
              </a>
            </div>
          </div>
        </div>

        <div
          className="flex gap-[12px] bg-[#f9f7ed] rounded-[8px] pt-[8px] pb-[8px] h-[50px] ps-[12px] cursor-pointer"
          onClick={ChatHandler}
        >
          <div className="flex">
            <img src="/assets/img/home/callNowOpen.svg" alt="callNow" />
          </div>
          <div className="flex items-center border-s-[1px] border-[#d7bc6d] ps-[16px]">
            <p className="text-[14px] text-[#815327] font-bold leading-none">
              {lang === "en"
                ? enPhrase["lblChatToFares"]
                : arPhrase["lblChatToFares"]}
            </p>
          </div>
        </div>

        {chatBtn === true ? (
          <LiveChatWidget chatSettings={chatSettings} license="16802304" visibility="minimized" />
        ) : (
          <LiveChatWidget chatSettings={chatSettings} license="16802304" visibility="maximized" />
        )}
      </div>
    </>
  );
};

export default ContactPop;
