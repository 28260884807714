import React, { useEffect } from "react";

const SuccessSurvayAdded = ({
  setIsOpenFeedBack,
  survayServices,
  feedBackHandler,
  setIsRequestSurvay,
  FeedBackListHandler,
  lang,
  arPhrase,
  enPhrase,
  expandHandler,
  setFeedBackIsOpen,
  specificSurvey,
  setSpecificSurvey,
  feedBackIsOpen,
  sidebarHeight,
  isOrderDetails,
}) => {
  console.log("isOrderDetails", isOrderDetails);



  const handleButtonClick = () => {
    // Set a flag in localStorage to indicate a refresh
    localStorage.setItem("refreshAndRunFunction", "true");
    // Refresh the page
    window.location.reload();
  };

  useEffect(() => {
    // Check if the flag is set in localStorage
    if (localStorage.getItem("refreshAndRunFunction") === "true") {
      // Remove the flag from localStorage
      localStorage.removeItem("refreshAndRunFunction");
      // Run the function after the page refresh
      openPopupMessage();
    }
  });

  // const runAnotherFunction = () => {
  //     setFeedBackIsOpen(true);
  //     console.log("EndedDone","EndedDone")
  // };

  const openPopupMessage = () => {
    // Your code to open a popup message
    console.log(
      "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
    );
    FeedBackListHandler(false);
  };

  return (
    <div
      className={`w-full md:w-[400px] shadow-[0_35px_60px_-15px_rgba(54,30,18,0.08)] ${
        isOrderDetails ? "" : "h-full"
      } border-[1px] border-[#E6D7A2] fixed bottom-0 ${
        lang === "en" ? "right-0" : "left-0"
      } flex flex-col items-center justify-center gap-[32px] px-[16px]  bg-[#fff] z-[10000] `}
      style={{ top: sidebarHeight }}
    >
      <img
        src="/assets/img/case-overview/closedIcon.svg"
        alt="close-icon"
        className={`cursor-pointer absolute top-[16px] ${
          lang === "en" ? "right-[16px]" : "left-[16px]"
        }`}
        // onClick={feedBackHandler}
        onClick={() => {
          feedBackHandler();
          window.location.reload();
        }}
      />

      <img
        src="/assets/img/case-overview/success-icon.svg"
        alt=""
        className="w-[136px] h-[136px]"
      />
      <p className="font-bold font-Almarai text-[24px] leading-[32px] text-center ">
        {lang === "en"
          ? enPhrase["lblSuccessMessageTxt"]
          : arPhrase["lblSuccessMessageTxt"]}
      </p>
      <p className="font-normal font-Almarai text-[16px] leading-[24px] text-center px-[24px]">
        {lang === "en"
          ? enPhrase["lblSuccessMessageTxt01"]
          : arPhrase["lblSuccessMessageTxt01"]}
      </p>

      {/* {specificSurvey ? null : survayServices?.services?.length !== 0 ? (
        <button
          className="w-[200px] h-[48px] rounded-[4px] py-[24px] px-[8px] flex items-center justify-center bg-[#A98031]"
          // onClick={handleClick}
          onClick={handleButtonClick}
        >
          <span className="font-bold font-Almarai text-[16px] leading-[18px] text-center text-[#fff] ">
            {lang === "en"
              ? enPhrase["lblCompletesurveys"]
              : arPhrase["lblCompletesurveys"]}
          </span>
        </button>
      ) : (
        <button
          className="w-[82px] h-[48px] rounded-[4px] py-[24px] px-[8px] flex items-center justify-center bg-[#F2ECCF]"
          onClick={() => {
            feedBackHandler();
            window.location.reload();
          }}
        >
          <span className="font-bold font-Almarai text-[16px] leading-[18px] text-center text-[#A98031] ">
            {lang === "en" ? enPhrase["btnFinish"] : arPhrase["btnFinish"]}
          </span>
        </button>
      )} */}

      {survayServices?.services?.length !== 1 && !isOrderDetails ? (
        <button
          className="w-[200px] h-[48px] rounded-[4px] py-[24px] px-[8px] flex items-center justify-center bg-[#A98031]"
          // onClick={handleClick}
          onClick={handleButtonClick}
        >
          <span className="font-bold font-Almarai text-[16px] leading-[18px] text-center text-[#fff] ">
            {lang === "en"
              ? enPhrase["lblCompletesurveys"]
              : arPhrase["lblCompletesurveys"]}
          </span>
        </button>
      ) : (
        <button
          className="w-[82px] h-[48px] rounded-[4px] py-[24px] px-[8px] flex items-center justify-center bg-[#F2ECCF]"
          onClick={() => {
            feedBackHandler();
            window.location.reload();
          }}
        >
          <span className="font-bold font-Almarai text-[16px] leading-[18px] text-center text-[#A98031] ">
            {lang === "en" ? enPhrase["btnFinish"] : arPhrase["btnFinish"]}
          </span>
        </button>
      )}
    </div>
  );
};

export default SuccessSurvayAdded;
