import { useContext } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";
import { Link } from "react-router-dom";

const FailedPayment = ({ setOpenModal,caseId,serviceid }) => {
  const lang = window.localStorage.getItem("lang");
  // const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <div className="flex flex-col p-[32px] items-center ">
      <img
        src="/assets/img/payment/failed.svg"
        alt="failed"
        className="w-[72px] h-[72px] mb-[32px]"
      />
      <p className="font-Almarai text-2xl text-[#FF3131] font-bold text-center mb-[16px]">
        {lang === "en"
          ? enPhrase["lblPaymentFailed"]
          : arPhrase["lblPaymentFailed"]}
      </p>
      <p className=" text-base text-[#666666] font-Almarai text-center max-w-[296px] mb-[32px]">
        {lang === "en"
          ? enPhrase["lblUnfortunatelypaymentFailed"]
          : arPhrase["lblUnfortunatelypaymentFailed"]}
      </p>
      <Link
       to={`/payment-method/${caseId}/${serviceid}`}
        className="flex flex-row justify-center items-center gap-2 w-full h-[48px] text-sm font-bold bg-[#B68A35] px-3 rounded-3 text-white "
        onClick={() => setOpenModal(false)}
      >
        {/* <img
          src="/assets/img/button/add.svg"
          alt="logout"
          className="w-5 h-5"
        /> */}
        <span>{lang === "en"
          ? enPhrase["lblOk"]
          : arPhrase["lblOk"]}</span>
      </Link>
    </div>
  );
};

export default FailedPayment;
