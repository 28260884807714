import React from 'react';

import { LiveChatWidget } from '@livechat/widget-react';
import { useState } from 'react';

import ServicesSlide from '../components/ServicesSlide';
import ServicesSlideItems from '../components/ServicesSlideItems';


const HomeBanner = ({ lang, enPhrase, arPhrase, services }) => {
  const [chatBtn, setChatBtn] = useState(true);

  const ChatHandler = () => {
    if (chatBtn) {
      setChatBtn(false);
    } else {
      setChatBtn(true);
    }
  };

  const scrollHandler = () => {
    window.scrollTo(document.body.scrollHeight, 700);
  };

  const chatSettings = {
    language: 'ar', // Set language to Arabic
  };

  return (
    <div
      id='home'
      className='max-w-full overflow-hidden bg-white wow fadeInUp bg-hero-pattern bg-no-repeat bg-cover bg-center	 pt-[64px] pb-[24px]  md:pt-[120px] md:pb-[120px] h-[741px] relative ]'>
      <div className='absolute top-0 bg-background-home w-full h-[741px]'></div>
      <section className=' relative'>
        <div
          className={`text-center drop-shadow-[0_2px_4px_rgba(0,0,0,0.75)] ${
            lang === "en" ? "max-w-[424px]" : "max-w-[424px]"
          } m-auto`}
        >
          {/* <h2 className="text-[30px] md:text-[44px] font-Almarai font-bold text-[#fff] ">
            {lang === "en"
              ? "We specialize in implementing"
              : "متخصصون في تنفيذ"}
          </h2>
          <h2
            className={`text-[30px] md:text-[44px] text-[#fff] font-Almarai font-bold ${
              lang === "en" ? "mt-0" : "mt-[16px]"
            } mb-[24px] `}
          >
            {lang === "en" ? "Judicial decisions" : "الأحكام القضائية"}
          </h2> */}
          <h2 className="text-[30px] md:text-[44px] font-Almarai font-bold text-[#fff] leading-[1.37]">
            {lang === "en"
              ? enPhrase["lbl_banner_title"]
              : arPhrase["lbl_banner_title"]}
          </h2>
        </div>
        <div className='text-center pl-[20px] md:pl-[0px] drop-shadow-[0_2px_4px_rgba(0,0,0,0.75)]  pr-[20px] md:pr-[0px]'>
          <p className='text-[#fff] max-w-[424px] text-[16px] md:text-[18px] font-Almarai  text-center m-auto'>
            {lang === 'en'
              ? enPhrase['lblEnforcementProcedures']
              : arPhrase['lblEnforcementProcedures']}
          </p>
        </div>
      </section>
      <div
        id='services'
        className={`mt-[177px]  relative ${
          lang === "en" ? "md:mt-[65px]" : "md:mt-[107px]"
        }  md:px-[0px]`}
      >
        {window && window.innerWidth <= 1024 ? (
          <ServicesSlide
            services={services}
            lang={lang}
            arPhrase={arPhrase}
            enPhrase={enPhrase}
          />
        ) : (
          <ServicesSlideItems
            services={services}
            lang={lang}
            arPhrase={arPhrase}
            enPhrase={enPhrase}
          />
        )}
        {/* <ServicesSlide
          services={services}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase}
        /> */}
        {/* <ServicesSlideItems services={services}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase} /> */}
        {/* <ServicesSlideLatest
          services={services}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase}
        /> */}
      </div>
      {/* <div
        className={
          lang === "ar"
            ? "mt-[16px] ml-[24px]  md:ml-[78px] cursor-pointer md:mt-[8px]"
            : "mt-[8px]  mr-[78px] cursor-pointer"
        }
      >
        {window && window.innerWidth < 1024 ? (
          <div className="flex items-center justify-end" onClick={ChatHandler}>
            <div
              className={`relative w-[112px] h-[32px] bg-[#fff] rounded-md border-[1px] border-solid border-[#e6d7a2] py-[7px] pr-[12px] pl-[46px] `}
            >
              <p
                className={`text-[14px] font-Almarai font-bold w-[102px] mt-[-3px] ${
                  lang === "en" ? "text-center ml-[-40px]" : ""
                }`}
              >
                {lang === "en" ? "Adel" : "عادل"}
              </p>
            </div>
            <div
              className={`" absolute w-[60px] h-[60px] bg-[#b68a35] rounded-[30px]" ${
                lang === "en"
                  ? "right-[22px] md:right-[56px]  rounded-[30px]"
                  : "left-[22px] md:left-[56px] rounded-[30px]"
              }`}
            ></div>
            <section
              className={`absolute  ${
                lang === "en"
                  ? "right-[22px] md:right-[56px] "
                  : "left-[22px] md:left-[56px]"
              }`}
            >
              <img src="/assets/img/home/fares.png" alt="" />
            </section>
          </div>
        ) : (
          <div className="flex items-center justify-end" onClick={ChatHandler}>
            <div
              className={`relative w-[154px] h-[32px] bg-[#fff] rounded-md border-[1px] border-solid border-[#e6d7a2] py-[7px] pr-[12px] pl-[46px] `}
            >
              <p
                className={`text-[14px] font-Almarai font-bold w-[102px] mt-[-3px] ${
                  lang === "en" ? "text-center ml-[-40px]" : ""
                }`}
              >
                {lang === "en"
                  ? enPhrase["lblChatToFares"]
                  : arPhrase["lblChatToFares"]}
              </p>
            </div>
            <div
              className={`" absolute w-[60px] h-[60px] bg-[#b68a35] rounded-[30px]" ${
                lang === "en"
                  ? "right-[22px] md:right-[56px]  rounded-[30px]"
                  : "left-[22px] md:left-[56px] rounded-[30px]"
              }`}
            ></div>
            <section
              className={`absolute  ${
                lang === "en"
                  ? "right-[22px] md:right-[56px] "
                  : "left-[22px] md:left-[56px]"
              }`}
            >
              <img src="/assets/img/home/fares.png" alt="" />
            </section>
          </div>
        )}
      </div> */}

      <div
        onClick={scrollHandler}
        className='absolute bottom-[15px] left-[49%] cursor-pointer hidden md:block w-[32px] h-[32px]'>
        <img src='/assets/img/home/Animation -mouse.gif' alt='scroll' />
      </div>

      {chatBtn === true ? (
        <LiveChatWidget chatSettings={chatSettings} license='16802304' visibility='minimized' />
      ) : (
        <LiveChatWidget  chatSettings={chatSettings}license='16802304' visibility='maximized' />
      )} 
      
    </div>
  );
};

export default HomeBanner;
