import React, { useEffect, useRef, useState } from "react";
import CaseOverViewComponent from "../components/case-overview";
import ThemeLayout from "../layouts/ThemeLayout";



export default function CaseOverView() {
  const [sidebarHeight, setSidebarHeight] = useState(0);
  const navbarRef = useRef(null);

useEffect(() => {
  if (navbarRef.current) {
    setSidebarHeight(navbarRef.current.offsetHeight);
  }
}, [navbarRef]);

console.log("serviceservice",sidebarHeight)

  return (
    <ThemeLayout navbarRef={navbarRef}>
      <CaseOverViewComponent sidebarHeight={sidebarHeight}/>
    </ThemeLayout>
  );
}
